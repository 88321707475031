import React from "react";

import Container from "components/Container";

import circleBackground from "assets/circle.svg";
import controlPangelImage from "assets/control-panel-image.svg";
import adjustScaleImage from "assets/adjust-scale-image.svg";
import timeManagementImage from "assets/time-management-image.svg";

const About = () => {
  return (
    <Container>
      <h2 className="font-header text-center tracking-tight font-bold text-purple-600 text-4xl lg:text-5xl">
        Why Tidbit
      </h2>
      <div
        className="flex flex-col py-12 content-between text-center bg-no-repeat bg-left-top bg-16 md:bg-32 lg:flex-row lg:bg-right-top lg:justify-between lg:bg-contain"
        style={{ backgroundImage: `url(${circleBackground})` }}
      >
        <div>
          <img
            className="h-64 w-full"
            src={controlPangelImage}
            alt="better control"
          />
          <p className="font-bold text-purple-900 text-2xl">
            Control digital habits
            <br />
            and manage distraction
          </p>
          <p className="mt-2 text-purple-600">
            Clarity over screen usage and
            <br />
            personalised actionable insights
          </p>
        </div>
        <div className="mt-4 lg:mt-0">
          <img
            className="h-56 w-full"
            src={adjustScaleImage}
            alt="compounding effect"
          />
          <p className="font-bold text-purple-900 text-2xl mt-8">
            Small adjustments,
            <br />
            compounding effect
          </p>
          <p className="mt-2 text-purple-600">
            Gain confidence from
            <br />
            success to form lifelong habits
          </p>
        </div>
        <div className="mt-4 lg:mt-0">
          <img
            className="h-56 w-full"
            src={timeManagementImage}
            alt="seamless integration"
          />
          <p className="font-bold text-purple-900 text-2xl mt-8">
            Easy and seamless to
            <br />
            add to existing routines
          </p>
          <p className="mt-2 text-purple-600">
            Intervenes during real-time usage and
            <br />
            adapts according to your preferences
          </p>
        </div>
      </div>
    </Container>
  );
};

export default About;
