import React from "react";
import logo from "assets/logo.svg";
import horizontalPadding from "constants/style";

const Logo = () => (
  <div className={horizontalPadding}>
    <img className="h-8 sm:h-12" src={logo} alt="tidbit" />
  </div>
);

export default Logo;
