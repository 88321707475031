import React from "react";

import Layout from "components/Layout";
import Logo from "components/Logo";

import Intro from "./Intro";
import About from "./About";
import Footer from "./Footer";
import Feature from "./Feature";

const App = () => {
  return (
    <Layout>
      <Logo />
      <Intro />
      <About />
      <Feature />
      <Footer />
    </Layout>
  );
};

export default App;
