import React from "react";

/**
 * @param {{children: JSX.Element}} [Props]
 */
const Layout = ({ children }) => {
  return (
    <div className="antialiased max-w-screen-xl mx-4 md:mx-12 font-sans bg-gray-100 my-6 md:my-16">
      {children}
    </div>
  );
};

export default Layout;
